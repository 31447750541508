<template>
    <div>
        <BigHeader>
            <template v-slot:title>
                Ошибка 404
            </template>
            <template v-slot:desc>
                У нас нет страницы по указанному адресу.<br/>
                Проверьте адресную строку, возможно вы ошиблись при вводе данных
            </template>
            <template
                v-if="!isSbg"
                v-slot:contentActions
            >
                <router-link
                    :to="{ name: 'Landing' }"
                >
                    На главную
                </router-link>
            </template>
        </BigHeader>
    </div>
</template>

<script>
import BigHeader from '@/components/common/big-header/BigHeader'

import sbg from '@/mixins/sbg'

export default {
    name: 'NotFound',
    mixins: [
        sbg
    ],
    components: {
        BigHeader,
    }
}
</script>