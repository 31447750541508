<template>
    <div class="big-header">
        <div class="big-header__wrapper">
            <Header/>
            <h1 class="big-header__title">
                <slot name="title"/>
            </h1>
            <div class="big-header__bottom">
                <Bar/>
                <div class="big-header-calc">
                    <div>
                        <div class="big-header-calc__top">
                            <p class="big-header-calc__title">
                                Сумма займа
                            </p>
                            <p class="big-header-calc__val">
                                {{ sum | price }} ₽
                            </p>
                        </div>
                        <Sum
                            v-model="sum"
                            :showRangeInfo="3"
                        />
                    </div>
                    <div>
                        <div class="big-header-calc__top">
                            <p class="big-header-calc__title">
                                Срок
                            </p>
                            <p class="big-header-calc__val">
                                {{ days.count }} {{ loanDaysString }}
                            </p>
                        </div>
                        <Days
                            v-model="days"
                        />
                    </div>
                    <div>
                        <Button @click.native="getLoan">
                            Получить
                        </Button>
                    </div>
                </div>
                <div
                    v-if="$slots.contentActions"
                >
                    <slot name="contentActions"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Header from '@/components/common/header/Header'
import './big-header.scss'
import price from '@/helpers/string/price'
import term from '@/helpers/string/term'

import contacts from '@/mixins/contacts'
import sbg from '@/mixins/sbg'

export default {
    name: 'BigHeader',
    mixins: [
        contacts,
        sbg
    ],
    data() {
        return {
            days: {
                type: 'days',
                count: 10
            },
            sum: 10000
        }
    },
    computed: {
        loanDaysString() {
            if (this.days.type === 'days') {
                return term(this.days.count)
            }

            return 'недель'
        },
    },
    methods: {
        getLoan() {
            this.$router.push({
                name: 'LoanPrimary'
            })
        }
    },
    components: {
        Days: () => import('@/components/calculators/days/Days'),
        Sum: () => import('@/components/calculators/sum/Sum'),
        Header,
        Bar: () => import('@/applications/landing/components/bar/Bar'),
    },
    filters: {
        price
    }
}
</script>